<template>
    <b-card
            class="workflow-view-wrapper"
    >
        <app-timeline>
            <app-timeline-item v-for="(log, i) in logList" :key="log.log_id" v-if="log.operator_num"
                               :title="log.cur_node + ' #'+(log.operator_num)"
                               :subtitle="log.remark.replace('/weaver/weaver.file.FileDownload','https://oa.xyb2b.com/weaver/weaver.file.FileDownload')"
                               :time="toTime(log.handle_time)"
                               variant="primary"
            />
        </app-timeline>
    </b-card>
</template>

<script>
    import {
        BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import {onUnmounted, ref} from '@vue/composition-api'
    import router from "@/router";
    import store from "@/store";
    import workflowStore from './workflowStore'
    import { useToast } from 'vue-toastification/composition'
    import { toTime} from '@core/utils/filter'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
    import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
    import Timeline from "../../components/timeline/Timeline";

    export default {
        components: {
            Timeline,
            BCard,
            BMedia,
            BAvatar,
            BCardText,
            BForm,
            BLink,
            BImg,
            BRow,
            BCol,
            BButton,
            BFormGroup,
            BFormInput,
            BFormFile,
            vSelect,
            useToast,

            AppTimeline,
            AppTimelineItem,
        },
        data() {
            return {
              logList:{},
            }
        },
        props: ["workflow_id"],
        setup() {
            const toast = useToast()

            // Register module
            if (!store.hasModule('workflow')) store.registerModule('workflow', workflowStore)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule('workflow')) store.unregisterModule('workflow')
            })

            const edit = function() {
                store.dispatch('workflow/searchLog',{workflow_id:this.workflow_id}).then(res => {
                    this.logList = res.data.data;
                })
            }


            const cancel = function() {
                this.$router.go(-1)
            }


            return {
                edit,
                cancel,
                toTime,
            }
        },
        created() {
            this.id = this.$route.query.id || 0;
            this.edit()
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
