import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function workflowUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: 'ID', sortable: true },
  /*  { key: 'workflow_no', label: '编号', sortable: true },*/
    { key: 'workflow_name', label: '流程标题', sortable: true },
    { key: 'request_id', label: 'RequestID', sortable: true },
   /* { key: 'workflow_type', label: '类型', sortable: true },*/
   /* { key: 'workflow_status', label: '状态', sortable: true },*/
  /*  { key: 'state', label: '状态', sortable: true },*/
 /*   { key: 'remark', label: '备注', sortable: true },
    { key: 'attachement', label: '附件', sortable: true },*/
    {key:'next_operator_name_list',label:'下一节点',sortable:true},
    { key: 'creator', label: '创建人', sortable: true },
    { key: 'add_time', label: '创建时间', sortable: true },
  /*  { key: 'modify_time', label: '修改时间', sortable: true },*/
    { key: 'updator', label: '修改人', sortable: true },
    { key: 'object_type', label: '业务表名称', sortable: true },
   /*     { key: 'actions', label: '操作' },*/
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const searchByTime = ref('')
  const orderBy = ref('workflow_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey, searchByTime], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('workflow/search', {
        search_key: searchKey.value,
        search_time: searchByTime.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '工作流列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveWorkflowTypeCode = value => {
    const val = String(value)
    if (val === '1') return {label: '渠道客户'}
    if (val === '2') return {label: '集团公司'}
    if (val === '3') return {label: '供应商'}
    if (val === '4') return {label: '仓库方'}
    if (val === '5') return {label: '物流方'}
    return {}
  }

  const resolveStateCode = value => {
    const val = String(value)
    if (val === '0') return {label: '禁用', color: 'secondary'}
    if (val === '1') return {label: '启用', color: 'success'}
    return {}
  }

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    searchByTime,
    orderBy,
    isSortDirDesc,
    refListTable,

    resolveWorkflowTypeCode,
    resolveStateCode,
    refetchData,

    // Extra Filters
  }
}
