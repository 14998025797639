<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
              v-model="limit"
              :options="limitOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchKey"
                class="d-inline-block mr-1"
                placeholder="搜索关键字..."
              />
            <!--  <b-button
                variant="primary"
                :to="{ name: 'apps-workflow-edit'}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>-->
            </div>
          </b-col>

          <!--serach by create_time-->
          <b-col
                  cols="12"
                  md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <flat-pickr
                      id="searchByTime"
                      v-model="searchByTime"
                      :config="{ enableTime: true,dateFormat: 'Y-m-d'}"
                      class="form-control"
                      placeholder="搜索时间"
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="searchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="orderBy"
        show-empty
        empty-text="未找到记录"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(id)="data">

          <div
              :id="`workflow_name-row-${data.item.id}`"
              @click="showWorkFlow(data.item.id)"
          >
            <b-link
                class="font-weight-bold d-block text-nowrap"
            >
              #{{ data.item.id }}
            </b-link>
          </div>
        </template>

        <template #cell(workflow_name)="data">
          {{ data.item.workflow_name}}
        </template>

        <template #cell(add_time)="data">
          {{ toTime(data.item.add_time)}}
        </template>

        <!-- Column: Type -->

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
            @click="changeState(data.item)"
          >
          <b-badge
            pill
            :variant="`light-${resolveStateCode(data.item.state).color}`"

          >
            {{resolveStateCode(data.item.state).label}}
          </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{ name: 'apps-workflow-view', query: { id: data.item.id } }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">浏览</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-workflow-edit', query: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="start"
              :total-rows="listTotals"
              :per-page="limit"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <!--流转意见modal-->
    <b-modal
            id="workflowModal"
            ok-title="关闭"
            ok-only
            centered
            size="lg"
            title="流转意见"
            ref="workflowModal"
    >
      <workflow-view
              ref="WorkflowView" :workflow_id="workflow_id">
      </workflow-view>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText, toTime } from '@core/utils/filter'
import workflowUseList from './workflowUseList'
import workflowStore from './workflowStore'
import WorkflowView from "./WorkflowView";

export default {
  components: {
    WorkflowView,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data(){
    return{
      workflow_id : 0,
    }
  },
  methods: {
    changeState: function(data) {
      const state = 1 - data.state
      store.dispatch('workflow/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },

    showWorkFlow: function(workflow_id) {
      this.workflow_id = workflow_id
      this.$refs["workflowModal"].show()
    },
  },
  setup() {
    // Register module
    if (!store.hasModule('workflow')) store.registerModule('workflow', workflowStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('workflow')) store.unregisterModule('workflow')
    })

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      searchByTime,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
      resolveWorkflowTypeCode,
      resolveStateCode,
    } = workflowUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      searchByTime,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      toTime,
      // UI
      resolveWorkflowTypeCode,
      resolveStateCode,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
